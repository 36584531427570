import { get, isEmpty, isUndefined, omitBy } from 'lodash';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';

import { getFeatureFlags } from '@utils/feature-flags';
import { assetQuery, categoriesQuery, floodlightQuery, seomaticQuery } from '../../content/query-snippets';
import { categoriesPropType, floodlightPageLoadEventPropTypes, recipesPropType } from '../../prop-types';
import { ContentMatrix } from '../../components/content-matrix';
import { decals } from '../../components/decal/constants';
import { extractSeoMetaFromEntry, seoPropDefaults, seoPropTypes } from '../../utils/extract-seo-meta-from-entry';
import { fetch } from '../../content/queries';
import { FilterGrid } from '../../components/filter-grid';
import { Layout } from '../../components/layout';
import { logger } from '../../utils/logger';
import { navigation } from '../../constants';
import { PageHeaderBlock } from '../../components/content-image';
import { RecipeCard } from '../../components/card';
import { setCacheHeaders } from '../../utils/set-cache-headers';
import Error from '../_error';

const DEFAULT_FILTER = 'All Recipes';

const imageDimensions = { width: '396px', height: '505px' };

const RecipesPage = ({ contentMatrix, filters, floodlightPageLoadEvent, metaContent, recipes, statusCode }) => {
  if (statusCode) {
    return <Error statusCode={statusCode} />;
  }
  return (
    <Layout title="Recipes" metaContent={metaContent} floodlightPageLoadEvent={floodlightPageLoadEvent}>
      <PageHeaderBlock
        heading="Our Recipes"
        subheading="Scroll down a little further and you can see we have many categories of recipes for you to choose from!"
        leftDecal={decals.recipes.left}
        rightDecal={decals.recipes.right}
      />
      <FilterGrid
        cardComponent={RecipeCard}
        defaultFilter={DEFAULT_FILTER}
        items={recipes}
        {...{ filters, imageDimensions }}
      />
      <ContentMatrix blocks={contentMatrix} />
    </Layout>
  );
};

RecipesPage.getInitialProps = async ({ query, res }) => {
  const preview = get(query, 'x-craft-live-preview');
  const token = get(query, 'token');
  const slug = 'recipes';
  const featureFlags = await getFeatureFlags();

  const contentQuery = gql`
    query getContentFromCMS {
      content: entries(siteId: "${process.env.cms.siteId}", slug: "${slug}") {
        ... on pages_pages_Entry {
          ${ContentMatrix.query}
          ${floodlightQuery}
        }
      }
      recipes: entries(section: "recipes", siteId: "${process.env.cms.siteId}") {
        ... on recipes_recipe_Entry {
          slug
          title
          recipeCategory {
            title
          }
          cookTime
          prepTime
          image: ${assetQuery('gridImage')}
        }
      }
      categories: ${categoriesQuery('recipeCategories')}
      ${seomaticQuery(slug)}
    }
  `;

  try {
    const data = await fetch({
      query: contentQuery,
      headers: omitBy({ 'x-craft-live-preview': preview, token }, isUndefined),
    });

    if (res) {
      if (isEmpty(data.recipes) && isEmpty(data.content[0].contentMatrix)) {
        // eslint-disable-next-line no-param-reassign
        res.statusCode = 404;
        return { statusCode: 404, featureFlags };
      }

      const isPreview = preview && token;
      setCacheHeaders({ res, isPreview });
    }

    const contentMatrix = get(data, 'content[0].contentMatrix', []);
    const entries = get(data, 'recipes', []);
    const categories = get(data, 'categories', []);
    const floodlightPageLoadEvent = get(data, 'content.floodlightPageLoadEvent[0]');
    const metaContent = extractSeoMetaFromEntry(data);

    const recipes = entries.map(({ recipeCategory, title, ...rest }) => ({
      category: get(recipeCategory, '[0].title'),
      href: `${navigation.RECIPES}/${rest.slug}`,
      heading: title,
      ...rest,
    }));
    const mappedCategories = categories.map(({ title }) => title);

    const filters = [DEFAULT_FILTER, ...mappedCategories];

    return { contentMatrix, filters, floodlightPageLoadEvent, metaContent, recipes, featureFlags };
  } catch (err) {
    logger.error('Failed to fetch recipes from CMS', err);
    return { statusCode: 500 };
  }
};

RecipesPage.propTypes = {
  contentMatrix: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: categoriesPropType.isRequired,
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  metaContent: PropTypes.shape(seoPropTypes),
  recipes: recipesPropType.isRequired,
  statusCode: PropTypes.number,
};

RecipesPage.defaultProps = {
  floodlightPageLoadEvent: undefined,
  metaContent: seoPropDefaults,
  statusCode: null,
};

export default RecipesPage;
